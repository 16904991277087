import {
  BEAM_PRIVACY_POLICY,
  BEAM_TERMS_OF_USE,
  DISCORD_URL,
  SUPPORT_URL,
  URLS,
} from '@/config/urls';
import { Button, chakra, Container, Flex, Link, Spacer, Stack, Text } from '@chakra-ui/react';
import { isExternalUrl } from '@meritcircle/ui';
import { Icon, IconName } from '@meritcircle/ui/components';

import useTranslation from 'next-translate/useTranslation';
import NextLink from 'next/link';
import { MouseEventHandler, PropsWithChildren } from 'react';

interface FooterLinkProps extends PropsWithChildren {
  href?: string;
}

interface FooterButtonProps extends PropsWithChildren {
  onClick?: MouseEventHandler;
}

const ctaProps = {
  display: 'inline-flex',
  alignItems: 'center',
  fontSize: 'md',
  px: { base: 0, md: 'md' },
  fontWeight: 'normal',
  lineHeight: 1,
  color: 'desiredDawn',
  transition: 'color .2s ease',
  textDecoration: 'none',
  _hover: {
    color: 'white',
  },
};

const FooterLink = ({ href, children }: FooterLinkProps): JSX.Element => (
  <Link href={href} color="white" fontWeight={400} isExternal={isExternalUrl(href)} {...ctaProps}>
    {children}
  </Link>
);

const FooterButton = ({ onClick, children }: FooterButtonProps): JSX.Element => (
  <Button variant="link" onClick={onClick} {...ctaProps}>
    {children}
  </Button>
);

export const Footer = () => {
  const { t } = useTranslation('common');

  return (
    <Flex
      as="footer"
      borderTop="1px solid"
      borderColor="border"
      w="100%"
      backgroundColor="black"
      zIndex={1}>
      <Container>
        <Flex
          alignItems={{ base: 'flex-start', md: 'center' }}
          h="100%"
          direction={{ base: 'column', md: 'row' }}>
          <NextLink href={URLS.DASHBOARD} passHref legacyBehavior>
            <Link py="xl" color="white" textDecoration="none" _hover={{ textDecoration: 'none' }}>
              <Text variant="muted" _hover={{ color: 'white' }}>
                {t('footer.page-title')}
              </Text>
            </Link>
          </NextLink>

          <chakra.nav
            ml={{ base: 0, md: 'auto' }}
            mb={{ base: 'xl', md: 0 }}
            alignItems="center"
            display="flex"
            h="100%">
            <Stack
              direction={{ base: 'column', md: 'row' }}
              spacing={{ base: 'xl', md: 'md', xl: 'lg' }}
              h="100%"
              flex={1}
              alignItems={{ base: 'flex-start', md: 'center' }}>
              <FooterLink href={DISCORD_URL}>
                <Icon icon={IconName.discord} color="white" />
                <chakra.span pl="sm">{t('footer.links.discord')}</chakra.span>
              </FooterLink>
              <FooterLink href={BEAM_TERMS_OF_USE}>{t('footer.links.terms-conditions')}</FooterLink>
              <FooterLink href={BEAM_PRIVACY_POLICY}>{t('footer.links.privacy-policy')}</FooterLink>
              <Spacer
                borderLeft="1px solid"
                borderColor="border"
                display={{ base: 'none', md: 'block' }}
              />

              <FooterLink href={SUPPORT_URL}>{t('footer.links.support')}</FooterLink>
              <FooterButton onClick={() => window.Cookiebot.show()}>
                {t('footer.links.cookie-preferences')}
              </FooterButton>
            </Stack>
          </chakra.nav>
        </Flex>
      </Container>
    </Flex>
  );
};
